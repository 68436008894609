
          @import "src/components/assets/Variables.module.scss";
        
@import url('@fontsource/roboto');
@import url("@fontsource/barlow");
@import url('@fontsource/noto-sans');


@import 'components/assets/Palette.module.scss';

@font-face {
    font-family: 'adelle';
    src: url('./fonts/Adelle/Adelle_light.otf');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'SignatureFont';
    src: url('./fonts/dancing-script-latin-400-normal.woff');
}
@font-face {
    font-family: 'adelle';
    src: url('./fonts/Adelle/Adelle_Reg.otf');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'adelle';
    src: url('./fonts/Adelle/Adelle_Semibold.otf');

    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'adelle';
    src: url('./fonts/Adelle/Adelle_Bold.otf');

    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'adelle';
    src: url('./fonts/Adelle/Adelle_ExtraBold.otf');

    font-weight: 900;
    font-style: normal;
}

.amcharts-AmChartsLogo-group {
    display: none;
}

html {
    font-size: 10px;
}

body {
    background-color: $black !important;

    &::-webkit-scrollbar-track {
        background-color: $backgroundDark;
        margin: 0 2;
    }

    &::-webkit-scrollbar-corner {
        background-color: $backgroundDark;
    }

    &::-webkit-scrollbar {
        width: 10px;
        background-color: $backgroundDark;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $border;
    }
    scrollbar-color: $background-paper;
    scrollbar-track-color: $border;
}

@media only screen and (max-width: 1600px) {
    html {
        font-size: 9px;
    }
}

*::-webkit-scrollbar {
    cursor: pointer;
    width: 6px;
    border-radius: 10px;
    height: 6px;
}

*::-webkit-scrollbar-track {
    cursor: pointer;
    background: $darkShades90;
    border-radius: 10px;
    margin-right: 5px;
}

*::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: $darkShades70;
    border-radius: 10px;
    margin-right: 5px;
}

::-webkit-scrollbar-corner {
    visibility: hidden;
    background: $darkShades90;
    border-radius: 10px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s;
}
