
          @import "src/components/assets/Variables.module.scss";
        
.truncatedText {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.TruncTooltip {
    :global {
 
        .ant-tooltip-inner {
            max-height: 100px !important;
            max-width: 220px !important;
            overflow: auto;
            position: relative !important;
            z-index: 9999;
        }
    }
}
