
          @import "src/components/assets/Variables.module.scss";
        
@mixin cellBorder {
    border-bottom: 0.1px solid $border;
    font-family: Roboto;
}

@mixin paginationButton {
    color: $greyShades !important;
    background-color: $background-paper !important;
    width: 32px;
    height: 32px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 1.2rem;
    cursor: pointer;

    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $border;
    border-radius: 18px;
}

.DataGridContainer {
    margin: 24px auto;

    :global {
        .ant-table-tbody > tr.ant-table-placeholder:hover > td {
            background-color: unset !important;
        }

        .ant-table-content {
            overflow: auto hidden !important;
            border-radius: 8px;

            &::-webkit-scrollbar-track {
                margin: 0 2;
            }

            &::-webkit-scrollbar {
                width: 12px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: $border;
            }

            scrollbar-color: $background-paper;
            scrollbar-track-color: $border;
        }

        .ant-table {
            border: 1px solid $border;
            border-bottom: none;
            border-radius: 8px;
            scrollbar-width: thin;

            background: var(--border-color-dark, $border);
            box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.04);
        }

        .ant-table-bordered {
            border-right: none;

            .ant-table-container {
                border-left: none;
            }

            table {
                border-top: none !important;
            }

            .ant-table-cell {
                border-bottom: 0.1px solid $border !important;
                border-right: 0.1px solid $border !important;
            }
        }

        .ant-table-cell {
            font-family: Roboto;
            font-style: normal;
            line-height: 18px;
            letter-spacing: 0.4px;
            color: $white;
            padding: 6px 12px;
            font-weight: normal;
            font-size: 1.4rem;
        }

        .ant-table-thead {
            .ant-table-cell {
                background-color: $tableHeadBg;
                color: $text-secondary;
                font-family: Roboto;
                height: 45px;
                font-weight: bold;
                border-bottom: none;

                .ant-table-filter-trigger.active {
                    color: $primary;
                }

                &:hover {
                    .ant-table-filter-column {
                        color: $primary;

                        .ant-table-filter-trigger.active {
                            color: $primary;
                        }
                    }
                }
            }

            tr {
                &:first-of-type {
                    th:first-of-type {
                        border-top-left-radius: 8px;
                    }

                    th {
                        &:last-of-type {
                            border-top-right-radius: 8px;
                        }
                    }
                }
            }

            th.ant-table-column-has-sorters:hover {
                background-color: $tableHeadBg;
                color: $primary;
            }

            .ant-table-thead .ant-table-cell-fix-left:hover {
                background: $tableHeadBg !important;
            }

            .ant-table-cell-fix-left:hover {
                background: $tableHeadBg !important;
            }
        }

        .ant-table-tbody {
            tr:last-of-type {
                border-bottom: none;

                td:first-of-type {
                    border-bottom-left-radius: 8px;
                }

                td:last-of-type {
                    border-bottom-right-radius: 8px;
                }
            }

            .ant-table {
                &-row {
                    background-color: $backgroundCard;

                    &:hover {
                        td {
                            background-color: $backgroundCard;
                        }
                    }
                }

                &-cell {
                    @include cellBorder();
                    background-color: $backgroundCard;
                }
            }
        }

        .ant-table-summary {
            .ant-table-cell {
                background-color: $tableHeadBg;
            }
            tr {
                td {
                    border-bottom: 1px solid $border;
                }
            }
        }

        .ant-table-cell-scrollbar {
            box-shadow: none !important;
        }

        .ant-table-cell-fix-left {
            z-index: 998;

            &:hover {
                background: $tableHeadBg !important;
            }
        }

        .ant-table-row-expand-icon-cell {
            background-color: $tableHeadBg !important;
        }

        .ant-table-row-expand-icon {
            background: $background-paper;
        }

        .ant-table-row-expand-icon:focus-visible,
        .ant-table-row-expand-icon:hover {
            color: $white;
        }

        .ant-table-expanded-row-fixed {
            padding: 0px 0px;
            margin: -30px -16px;
        }

        .ant-pagination {
            display: flex;
            justify-content: flex-end;
            margin-top: 16px;

            .ant-pagination-total-text {
                color: $white;
            }

            .ant-pagination-prev,
            .ant-pagination-next {
                button {
                    @include paginationButton();
                }

                &.ant-pagination-disabled {
                    display: none;
                }
            }

            .ant-pagination-jump-prev,
            .ant-pagination-jump-next,
            &-item {
                @include paginationButton();

                .ant-pagination-item-ellipsis,
                a {
                    color: $greyShades !important;
                    width: 100%;
                    padding: 0;
                }
            }

            li.ant-pagination-item-active {
                background-color: $buttonPrimaryDefault !important;

                a {
                    color: $typographyDark !important;
                }
            }

            &-options {
                width: unset !important;

                .ant-select-selector {
                    background-color: $background-paper !important;
                    border: none;
                    width: 100%;
                    color: $greyShades !important;

                    .ant-select-selection-item::after {
                        display: none;
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 1.2rem;
                    }
                }

                .ant-select-arrow {
                    color: $greyShades !important;
                }

                .ant-select-dropdown {
                    background: $backgroundDark !important;
                    border: 1px solid $border !important;
                    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.06);
                    padding: 10px 0px;
                    color: $white;

                    .ant-select {
                        &-item {
                            color: $white !important;

                            &:hover {
                                background: $darkShades90 !important;
                            }

                            background: $backgroundDark !important;
                        }
                    }

                    .ant-select-item-option-selected {
                        background: $darkShades90 !important;
                    }
                }
            }

            .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
            .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
                color: $primary;
            }
        }

        .ant-empty-description {
            color: $white;
        }
        .ant-table-column-sorter-up.active,
        .ant-table-column-sorter-down.active {
            color: $primary;
        }
    }
}

.Active {
    :global {
        .ant-pagination {
            .ant-pagination-prev {
                display: none;
            }
        }
    }
}

:global {
    .ant-table-filter-dropdown {
        background-color: $backgroundDark !important;
        max-width: 300px;
        &-search {
            background-color: $backgroundDark;
            border-bottom: 1px solid $border ;

            .ant-table-filter-dropdown-search-input {
                background-color: $backgroundDark;
                color: $white;
                border: 1px solid $border !important;

                .anticon {
                    color: $white;
                }

                .ant-input {
                    background-color: $backgroundDark;
                    color: $white;
                }
            }
        }

        ul {
            background-color: $backgroundDark;
            color: $white;

            li {
                background-color: $backgroundDark !important;
                color: $white !important;

                .ant-checkbox-inner {
                    background-color: $backgroundSecondary !important;
                    border: $border;
                }
            }
        }

        &-btns {
            border-top: 1px solid $border;
            padding: 6px;

            .ant-btn-link {
                color: $white !important;
            }

            .ant-btn-primary {
                background-color: $background-paper !important;
                border: none;
                border-radius: 5px;
                width: 50px;
            }
        }

        .filter-text {
            margin-left: 8px;
        }
    }

    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
        border-color: $borderGreen;
    }
}

.DetailsContainer {
    width: 100%;
    text-align: end;

    .Icon {
        color: $iconDefault;
        font-size: 2.2rem;

        &.ActiveButton,
        &:active,
        &:hover {
            color: $typographySecondary;
        }
    }

    .Button {
        height: 32px;
        width: 32px;
        margin-right: 12px;
        background: $darkShades80;
        border-radius: 16px;

        &.ActiveButton,
        &:active,
        &:hover {
            background-color: $darkShades10;
        }
    }
}
