
          @import "src/components/assets/Variables.module.scss";
        
.TagsContainer {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;

    :global {
        .ant-tag {
            display: flex;
            align-items: center;
            height: 28px;
            gap: 4px;
            background: $darkShades90;
            text-align: center;
            border: none;
            color: $white;
            border-radius: 0 24px 24px 20px;
            
            padding: 6px 15px;
            text-align: center;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px; /* 133.333% */
            letter-spacing: 0.4px;
            svg {
                color: $primary;
            }
        }

        .new-tag {
            background-color: $background-paper;
            border: 1px dashed $greyShades;
            color: $greyShades;
            cursor: pointer;

            &:hover {
                border: 1px dashed $primary;
                color: $primary;
            }
        }

        input.tag-input {
            width: 150px;
            background: $backgroundSecondary !important;
            border: 1px solid #707986 !important;
            border-radius: 20px;
            color: $white;
        }
    }
}
